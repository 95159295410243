img{
    max-width: 100%;
    min-height: 100%;
    object-fit: contain;
}

.slideshow{
    height: 100%;
    top: 0;
    position: relative;
    width: 100%;
    margin: 0;
}

.slider-container{
    position: relative;
    display: block;
    padding-bottom: 5%;
}
