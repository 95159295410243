/* p{
    margin: 2rem;
} */
.custom-pic{
    display: flex;
    align-items: center;
}

.about-content{
    background-color: #141010;
    color: white;
    /* text-align: center; */
    font-weight: 300;
    margin: auto;
}

.card-body{
    line-height: 1.5rem;
}

.row.about-container{
    margin: 7% 0;
}