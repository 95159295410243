body{
    /* height: 100%; */
    /* padding-bottom: 73px; */
    /* overflow-y:auto; */
    background-color: black;
}

.navbar-custom{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    /* border-bottom: 0.5px #C9D6DF solid; */
    /* background-color: black; */
    /* padding: 12px 0; */
}

nav.navbar-custom{
    background-color: black;
    /* margin-bottom: 4%; */
}

/* .navbar-nav{
    margin-left: 5%;
} */
.nav-link{
    transition: 0.4s ease-out;
}

.navbar-nav .nav-link.active, .navbar-nav .show > .nav-link{
    color: white;
}

.navbar-nav .nav-link.active:hover{
    color: #C9D6DF;
}

a.nav-link:hover{
    
    transform: translateY(-5px);
}

.navbar-toggler{
    border: none;
    border-radius: 0;
}


.container-custom{
    height: 100%;
    padding: 0;
}

.content{
    z-index: 1;
}

.logo-custom-container{
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.logo-custom{
    /* display: flex; */
    width: 23rem;
    height: 6rem;
    margin-right: 50%;
}

.logo-container{
    width: 50%;
}