.row{
    margin-bottom: 20px;
    height: auto;
}

.pic-port-custom{
    cursor: pointer;
    opacity: 1;
    
    -webkit-transition: opacity 0.5s;
}

.pic-port-custom:hover{
    transition: 0.5s;
    opacity: 0.5;   
    filter: alpha(opacity=40);
}