html{
    overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  height: 100%;
}

body{
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

h2{
    margin: 1rem;
    color: white;
}

button.nav-link{
    color: white;
    font-weight: 300;
}

button.nav-link:hover{
    color: #C9D6DF;
}

.card.portofolio-header{
    background-color: #141010;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
}

.custom-tab{
    margin-top: 20px;
}

.nav-item{
    margin-right: 50px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: white;
    background-color: black;
    border-bottom: 1px #C9D6DF solid;
    border-radius: 0;
}

.nav-link{
    color: black;
}

.pill-custom{
    color: black;
}