.custom-footer{
    font-weight: 400;
    font-size: 1.2rem;
    padding: 2% 15%;
    max-width: 100%;
    z-index: -1;
    background-color: #1E2022;
    margin-bottom: 0;
    border-top-color: #222831;
}

.custom-footer .nav-link{
    color: white;

}

.border-top{
    border-color: #222831;
}

.footer-copyright{
    padding-top: 4%;
    text-align: center;
    color: white;
    font-size: 0.9rem;
}

.gallery-land{
    background: #F0F5F9;
    max-width: 100%;
}

ul.css-1f01pxc{
    margin-bottom: 0;
}

li.footer-home{
    margin-right: 5px;
}